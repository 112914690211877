.transaction-hero-container {
    background-image: url('../../images/CupsHero.PNG');
    background-size: contain;
    background-position: center;
    /* padding: 100px 0 200px 0; */
    height: 600px;
    /* margin-top:70px; */
    text-align: center;
    background-color: #e1b0af;
  }
  @media (max-width: 768px) {
    /* Adjust height for mobile devices */
    .hero-container {
      background-size: cover;
        height: 300px; /* Change to your desired height for mobile */
    }
}
  
  .hero-content {
    max-width: 800px;
    margin: 0 auto;
    color: #AA4A44;
    display: flex;
    justify-content: center;
  }
  
  .hero-title {
    font-size: 40px; 
    font-weight: bold;
    font-family: 'customFont', sans-serif;
    color: #AA4A44;
    margin-right: 8px;
    letter-spacing: 2px;
    margin-top: 0;
    margin-bottom: 3px;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
  }
  @media (max-width: 768px) {
    /* Adjust height for mobile devices */
    .hero-title {
      top: 22%;
    }
  }
  
  .hero-subtitle {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .hero-description {
    font-size: 18px;
    margin-bottom: 40px;
    max-width: 200px;
  }
  
  .hero-btn {
    background-color: #ff5a5f;
    color: #fff;
    font-size: 18px;
    padding: 15px 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .hero-btn:hover {
    background-color: #ff3c41;
  }