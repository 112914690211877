@font-face {
  font-family: 'customFont';
  src:url('../../fonts/Hellbone.otf') format('truetype');
}

@font-face {
  font-family: 'CampanaScript';
  src: url('../../fonts/CampanaScript.otf') format('truetype');
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    width: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    background-color: #fdfdfd;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    max-width: 310px;
  }
  
  .product-title {
    font-size: 1.1em;
    color: #333;
    font-family: 'customFont', sans-serif;
    font-size: 20px;
    letter-spacing: 2px;
  }