.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .image-square, .upload-square {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border: 2px dashed #bbb;
    cursor: pointer;
    overflow: hidden;
  }
  
  .image-square img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .upload-square {
    font-size: 14px;
    color: #777;
  }
  