.fortune-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .popup-content {
    position: relative;
    background: #fff;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  h2 {
    color: #333;
  }
  
  p {
    margin-top: 10px;
    color: #666;
  }