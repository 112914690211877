.transaction-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(244, 239, 194, .9);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: -300px;
    /* border: 4px solid rgba(255, 255, 255, 0.7); */
  }

  .send-transaction {
    background-color: #d5918e;
    color: #fff;
    /* padding: 12px 18px; */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    min-width: 48px;
    width:50%;
    border-radius: 24px;
    transition: transform 0.2s ease-in-out;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-top: 5px;
  }