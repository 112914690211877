/* EmailForm.css (create a separate CSS file) */

/* Container styles */
.email-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--color-secondary);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: -300px;
    /* border: 4px solid rgba(255, 255, 255, 0.7); */
  }
  
  @media (max-width: 768px) {
    /* Adjust height for mobile devices */
    .email-container {
      margin-top: -4px; 
    }
  }
  
  .heading {
    font-size: 14px;
    display: flex;
    justify-content: left;
    /* color: #AA4A44; */
    margin-bottom: 20px;
  }
  
  /* Input field styles */
  label {
    display: block;
    margin-bottom: 6px;
    font-weight: bold;
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
  }
  
  input {
    width: 92%;
    padding: 5px;
    margin-bottom: 15px;
    border-right: 3px solid black;
    border-bottom: 3px solid black;
    border-top: none;
    border-left: none;
    border-radius: 4px;
    font-size: 15px;
  }
  
  textarea {
    width: 90%;
    height: 100px;
    padding: 10px;
    margin-bottom: 15px;
    border-right: 3px solid black;
    border-bottom: 3px solid black;
    border-top: none;
    border-left: none;
    border-radius: 4px;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  
  textarea {
    resize: vertical;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  /* Button styles */
  .send {
    background-color: #d5918e;
    color: #fff;
    /* padding: 12px 18px; */
    border-right: 3px solid black;
    border-bottom: 3px solid black;
    border-top: none;
    border-left: none;
    font-size: 16px;
    cursor: pointer;
    min-width: 48px;
    width:50%;
    transition: transform 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-top: 5px;
  }
  
  .send:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    background-color: #AA4A44;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  